import { Component } from "react";
import { Box, Card, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import { appContext } from "../../../AppContext";
import IbssSvgIcon from "../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../Common/AllsvgIcons";
import IbssActionButton, { IActionButton } from "../../../Components/Buttons/ActionButton/IbssActionButton";
import IbssDataGrid from "../../../Components/Data/DataGrid/IbssDataGrid";
import { DataEntryRole } from "../../../Providers.Api/Roles/RoleRepository";
import DuplicateSystemFilterModal from "../../../Components/Dialogs/DuplicateSystemFilterDialog/DuplicateSystemFilterDialog";
import { forEach } from "lodash";
import AddSystemFilterModal from "../../../Components/Dialogs/AddSystemFilterDialog/AddSystemFilterDialog";
import CreateIcon from '@mui/icons-material/Create';
import { PagedResponse } from "../../../Providers.Api/Models";
import { SystemFilter } from "./DataModels";
import ConfirmModal from "../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import IbssIconButton from "../../../Components/Buttons/IconButton/IbssIconButton";

class SystemFilters extends Component<RouteComponentProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }

    private rows = new Array<IGridRow>();

    constructor(props: RouteComponentProps)
    {
        super(props);
        
        this.state =
        {
            isLoading: false,
            showDeleteConfirmationModal: false,
            showDuplicatePolicyModal: false,
            showAddModal: false,
            systemFilters: new Array<IGridRow>(),
            selectedFilterId: '',
            selectedFilters: [],
            systemFiltersAll: [],
            allRoles: [],
            editFilterId: null,
            buildingId: this.appState.buildingId,
            getRolesCallFailed: false
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        this.loadSystemFilters();
        try 
        {
            const roles = await appContext().ibssApiClientV2.v2.roles.get<DataEntryRole[]>({});
            this.setState({ allRoles: roles });
        } catch (error) 
        {
            this.setState({ getRolesCallFailed: true });
        }
    }

    public componentDidUpdate(prevProps: RouteComponentProps, prevState: IState): void
    {
        if (prevState.buildingId !== this.state.buildingId)
        {
            this.buildingIdChanged(this.state.buildingId);
        }
    }

    public async buildingIdChanged(buildingId: number): Promise<void>
    {
        if (buildingId)
        {
            this.props.history.push(`/admin/system-filters/buildingId=${buildingId}`);
        }
        await this.setState({ buildingId: buildingId });
        this.loadSystemFilters();
    }

    private async loadSystemFilters(): Promise<void>
    {
        this.setState({ isLoading: true });
        const filters = await appContext().ibssApiClientV2.v2.byNodeid.filters.get<PagedResponse<SystemFilter[]>>({
            nodeId: this.state.buildingId,
            select: SystemFilter,
        });
        this.rows = new Array<IGridRow>();

        for (let i = 0; i < filters.value.length; i++)
        {
            const filter = filters.value[i];
            this.rows.push(
            {
                id: filter.Filter_Id ?? "",
                name: filter.Name,
                owner: filter.Filter,
                status: filter.Status,
                workspaces: 'View',
                buildingBypass: filter.Building_Bypass
            })
        }

        this.setState({ systemFilters: this.rows, isLoading: false, systemFiltersAll: filters.value });
    }
    
    private async deletePoliciesClicked(): Promise<void>
    {
        this.setState({ showDeleteConfirmationModal: true });
    }

    private deleteConfirmationModalClosed(): void
    {
        this.setState({ showDeleteConfirmationModal: false });
    }

    private async deleteConfirmationModalSubmitted(): Promise<void>
    {
        this.setState({ isLoading: true });
        if (this.state.selectedFilters.length > 0)
        {
            this.state.selectedFilters.forEach(async (filterId) => {
                await appContext().ibssApiClientV2.v2.byNodeid.filters.byFilterid.delete({
                    nodeId: this.appState.buildingId,
                    filterid: filterId
                });
            });
        }
        this.loadSystemFilters();
        this.setState({ showDeleteConfirmationModal: false, isLoading: false });
    }

    private duplicationModalClosed(): void
    {
        this.setState({ showDuplicatePolicyModal: false });
    }

    private async addFilterClicked(): Promise<void>
    {
        this.setState({ showAddModal: true, editFilterId: null });
    }

    private async addModalClosed(): Promise<void>
    {
        this.setState({ showAddModal: false });
    }

    private async duplicationClicked(): Promise<void>
    {
        const selectedFilter = this.state.systemFiltersAll.find((filter) => filter.Filter_Id === this.state.selectedFilters[0]);
        this.setState({ 
            showDuplicatePolicyModal: true,
            selectedFilterId: selectedFilter?.Filter_Id ?? ''
        });
    }

    private selectedPoliciesChanged(filtersIds: string[]): void
    {
        this.setState({ selectedFilters: filtersIds });
    }

    private openEditFilterModal(filter: IGridRow): void
    {
        this.setState({ showAddModal : true, editFilterId: filter.id });
    }
    
    private editFilter(filter: IGridRow): React.ReactNode
    {
        return (
            <IbssIconButton className="pointer" onClick={() => this.openEditFilterModal(filter)}>
                <CreateIcon />
            </IbssIconButton>
        )
    }

    private statusFilter(filter: IGridRow): string
    {
        switch (filter.status) 
        {
            case 'StatusInactive':
                return this.labels.HubLabelDisabled;
            case 'StatusActive':
                return this.labels.HubLabelEnabled;
            case 'StatusActiveDefault':
                return this.labels.HubLabelDefault;
            default:
                return '';
        }
    }

    private getBuildingBypass(filter: IGridRow): string
    {
        return filter.buildingBypass ? this.labels.HubLabelTrue : this.labels.HubLabelFalse;
    }

    public render(): JSX.Element
    {
        const columns =
        [
            {
                field: 'name',
                headerName: this.labels.HubLabelViewFiltersTableFilterName,
                width: 280,
            },
            {
                field: 'status',
                headerName: this.labels.HubLabelStatus,
                width: 280,
                renderCell: (params: { row: IGridRow; }) => <>{this.statusFilter(params.row)}</>
            },
            {
                field: 'buildingBypass',
                headerName: this.labels.funcMultiBuilding_S,
                width: 280,
                renderCell: (params: { row: IGridRow; }) => <>{this.getBuildingBypass(params.row)}</>
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: 120,
                field: "",
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params: { row: IGridRow; }) => <>{this.editFilter(params.row)}</>
            },
        ];

        let buttons: IActionButton[] =
        [
           {
               label: this.labels.HubButtonDelete,
               icon: <IbssSvgIcon>{Icons.Bin}</IbssSvgIcon>,
               color: 'error',
               onClick: () => this.deletePoliciesClicked(),
               disabled: (this.state.selectedFilters.length == 0),
           },
           {
               label: this.labels.funcDuplicate,
               icon: <IbssSvgIcon>{Icons.GearIcon}</IbssSvgIcon>,
               color: 'primary',
               onClick: () => this.duplicationClicked(),
               disabled: (this.state.selectedFilters.length == 0 || this.state.selectedFilters.length > 1 || this.state.getRolesCallFailed),
           },
           {
               label: this.labels.HubLabelAddButton,
               icon: <IbssSvgIcon>{Icons.AddIcon}</IbssSvgIcon>,
               color: 'primary',
               onClick: () => this.addFilterClicked(),
               disabled: this.state.getRolesCallFailed
           }
       ]
        
        return (
            <Box className="page-height-exct-header">
                <Box className="rightPanel-main-content">
                    <Grid container>
                        <Grid xs={5} pr={2}>
                            <Box className="tablePanel" p={4}>
                                <Typography variant="h6" sx={{fontWeight:'regular'}} mb={2}>{this.labels.HubMenuSystemFilters}</Typography>
                                <Typography variant="body2" className="ui-text-light">{this.labels.HubLabelManagedSpaceFilterText}</Typography>
                            </Box>
                        </Grid>
                        <Grid xs={7} pr={2}>
                        <Box className="tablePanel" sx={{'& > :not(style)': { m: 2},}}>
                            <Box my={2} alignItems={'center'}>
                                <Box display={'flex'} alignItems={'center'} sx={{'& > :not(style)': { mt: 1},}}>
                                    <Box className="icon-bg-dark" mr={1} p={1.5}>
                                        <IbssSvgIcon sx={{color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                                            {Icons.SpacesIcon}
                                        </IbssSvgIcon>
                                    </Box>
                                    <Typography variant="h6" sx={{fontWeight:'regular'}}>{this.labels.HubLabelManagedSpaceFilter}</Typography>
                                </Box>
                                    <Box>
                                        <div style={{ justifyContent: 'right', paddingBottom: '5px' }}>
                                                <IbssActionButton buttons={buttons} />
                                        </div>
                                    </Box>
                                <Box>
                                  <Box>
                                    <IbssDataGrid
                                            className="view-booking-policies-data-grid"
                                            height="calc(-288px + 100vh)"
                                            disableRowSelectionOnClick
                                            onRowSelectionModelChange={e => this.selectedPoliciesChanged(e as string[])}
                                            checkboxSelection
                                            columns={columns}
                                            rows={this.state.systemFilters}
                                        />
                                  </Box>
                                  <AddSystemFilterModal
                                        roles={this.state.allRoles}
                                        show={this.state.showAddModal}
                                        onHide={() => this.addModalClosed()}
                                        nodeId={this.appState.buildingId}
                                        onSystemFilterCreated={() => this.loadSystemFilters()}
                                        filterId={this.state.editFilterId}
                                        />
                                    <ConfirmModal
                                        show={this.state.showDeleteConfirmationModal}
                                        modalHeading={this.labels.HubLabelConfirmDeleteSystemFilter}
                                        handleModal={() => this.deleteConfirmationModalClosed()}
                                        modalMessage={this.labels.HubLabelConfirmDeleteSystemFilterText}
                                        okButton={() => this.deleteConfirmationModalSubmitted()}
                                    />
                                    <DuplicateSystemFilterModal
                                        roles={this.state.allRoles}
                                        show={this.state.showDuplicatePolicyModal}
                                        onHide={() => this.duplicationModalClosed()}
                                        selectedfilterId={this.state.selectedFilterId ?? null}
                                        onSystemFilterCreated={() => this.loadSystemFilters()}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="h5"></Typography>
                            </Box>
                        </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }
}


export default withRouter(SystemFilters);

export interface IQueryParams
{
    buildingid: string;
}

export interface IState
{
    buildingId:number;
    isLoading: boolean;
    showDeleteConfirmationModal: boolean;
    showDuplicatePolicyModal: boolean;
    showAddModal: boolean;
    systemFilters: IGridRow[];
    selectedFilters:string[];
    selectedFilterId: string;
    systemFiltersAll: SystemFilter[];
    allRoles: DataEntryRole[];
    editFilterId: string | null;
    getRolesCallFailed: boolean;
}

export interface IGridRow
{
    id: string;
    name: string;
    workspaces: string;
    status: string;
    owner: string;
    buildingBypass: boolean;
}


